import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import globalMixin from "@/mixins/globalMixin";

import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/ru-RU";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI, { locale });

import YmapPlugin from "vue-yandex-maps";
const settings = {
  apiKey: "67f56bcd-c05b-4e21-9ad7-75e66c7f92d7",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
};
Vue.use(YmapPlugin, settings);

Vue.config.productionTip = false;
Vue.mixin(globalMixin);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import VueSecureHTML from "vue-html-secure";
Vue.use(VueSecureHTML);

import "@/assets/scss/main.scss";

import Meta from "vue-meta";
Vue.use(Meta);

import VueYandexMetrika from "vue-yandex-metrika";
/* eslint-disable no-undef */
if (
  process.env.NODE_ENV === "production" &&
  process.env.VUE_APP_YANDEX_METRIKA
) {
  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_YANDEX_METRIKA,
    router,
    env: process.env.NODE_ENV,
    debug: process.env.NODE_ENV !== "production",
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
