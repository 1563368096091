<template><component :is="currentView" /></template>

<script>
import DesktopTrucksView from "@/views/TrucksView/DesktopTrucksView.vue";
import MobileTrucksView from "@/views/TrucksView/MobileTrucksView.vue";

export default {
  name: "TrucksView",
  metaInfo: {
    title: "ЭО Порт транзит | Мои машины",
  },
  computed: {
    currentView() {
      return this.isMobile ? MobileTrucksView : DesktopTrucksView;
    },
  },
};
</script>
