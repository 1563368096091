<template>
  <section class="method">
    <div v-if="!isMobile" class="font1-24-30 method__title">
      Пополнение баланса
    </div>
    <MainInput
      title="Сумма пополнения"
      :value="paymentSum"
      v-mask="'######'"
      class="method__sum"
      @input="setSum"
    />
    <PaymentRadio
      :variants="variants"
      :value="currentMethod"
      @change="setMethod"
    />
    <MainButton
      title="Оплатить"
      :disabled="paymentSum < 10"
      class="method__button"
      @button-clicked="toPayment"
    />
  </section>
</template>

<script>
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import MainInput from "@/components/UI/controls/form/MainInput.vue";
import PaymentRadio from "./PaymentRadio.vue";
import { methodRadioData } from "../data/payment-data";

export default {
  name: "PaymentMethodForm",
  components: { MainButton, PaymentRadio, MainInput },
  data() {
    return {
      paymentSum: "",
      variants: methodRadioData,
      currentMethod: 1,
    };
  },
  methods: {
    setSum(value) {
      this.paymentSum = value;
    },
    setMethod(value) {
      this.currentMethod = value;
    },
    toPayment() {
      let action = this.variants.find(
        (item) => item.value === this.currentMethod
      ).action;
      this.$emit("pay", action, Number(this.paymentSum));
    },
  },
};
</script>

<style lang="scss" scoped>
.method {
  width: 100%;

  &__title {
    margin-bottom: 24px;
    color: $mainBlack;
  }

  &__sum {
    margin-bottom: 16px;
  }

  &__button {
    width: 100%;
    margin-top: 32px;
  }
}
</style>
