<template>
  <div class="input-wrapper" :class="{ disabled: disabled }">
    <div
      class="input"
      :class="[borderStyle, { disabled: disabled }]"
      @keyup.tab="start"
    >
      <div class="input__content" @click="start">
        <div :class="['input__content-prompt', { filled: isLabelTop }]">
          {{ title }}
        </div>

        <el-input
          ref="input"
          v-model="inputContent"
          :type="type"
          :class="['input__content-input', { filled: isLabelTop }]"
          :disabled="disabled"
          @blur="isInputFocused = false"
          @focus="onFocus"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainInput",
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInputFocused: false,
    };
  },
  computed: {
    inputContent: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isLabelTop() {
      return this.isInputFocused || this.inputContent.length;
    },
    borderStyle() {
      if (this.isInputFocused) {
        return "is-focus";
      }
      return this.inputContent.length ? "is-success" : "";
    },
  },
  methods: {
    start() {
      if (!this.disabled) {
        this.$refs.input.focus();
      }
    },
    onFocus() {
      if (!this.disabled) {
        this.isInputFocused = true;
        this.$emit("clearValidation");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.is-focus {
  border: none;
  @media (max-width: 1279px) {
    border: 1px solid $select;
  }
}
.is-success {
  border: none;
}
.input-wrapper.disabled {
  cursor: not-allowed;
}
.input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-right: 17px;
  border-radius: 12px;
  background: $bckgrnd;
  @supports (-webkit-touch-callout: none) {
    @media (max-width: 767px) {
      height: 62px;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &__phone {
    border-radius: 12px 0 0 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 12px;
    cursor: pointer;
    &-input {
      width: 100%;
      height: 0;
      padding: 0;
      border-width: 0;
      outline: none;
      font-size: 0;
      background: $bckgrnd;
      transition: height 0.3s ease;
      color: $mainInputValue;
      &.filled {
        height: 18px;
        font-size: 16px;
        font-weight: 500;
        @supports (-webkit-touch-callout: none) {
          @media (max-width: 767px) {
            height: 30px;
          }
        }
      }
      :deep(.el-input__inner) {
        height: inherit;
        padding: 0;
        border: none;
        color: $mainBlack;
        background-color: inherit;
      }
    }
    &-prompt {
      font-size: 14px;
      color: $greyText;
      transition: all 0.3s ease;
      &.filled {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>
