<template>
  <div class="confirm" v-loading="isLoading">
    <div class="confirm__content">
      <el-link class="confirm__link" @click="backToNumber">
        <img src="@/assets/images/chevron-left.svg" alt="back" />
      </el-link>

      <h1 class="confirm__title mobile__title">Подтверждение</h1>
      <span class="confirm__description mobile__subtitle">
        Мы отправили код на номер
      </span>
      <div class="confirm__phone">{{ parsedPhone }}</div>

      <div v-if="trueCode" class="confirm__code">{{ trueCode }}</div>

      <div class="confirm__code-wrapper">
        <div class="confirm__code">
          <input
            v-for="i in codeLength"
            :key="i"
            ref="code"
            v-model="code[i - 1]"
            type="tel"
            v-mask="'#'"
            class="confirm__code-item"
            :class="[{ success: hasSuccess }, { error: mobileHasError }]"
            :disabled="isLoading"
            @focus="clearValue(i)"
            @input="enterValue(i)"
          />
        </div>
        <transition>
          <template v-if="mobileHasError">
            <div class="customErrors">
              <ValidationErrorIcon />
              <div class="customErrors__text">СМС-код введен неверно</div>
            </div>
          </template>
        </transition>
      </div>
    </div>
    <TimerButton :timerInterval="timer" @button-clicked="getCodeAgainMobile" />
  </div>
</template>

<script>
import confirmMixin from "@/views/AuthViews/confirmView/confirmMixin";

export default {
  name: "MobileConfirmView",
  mixins: [confirmMixin],
  data() {
    return {
      code: [],
      hasError: false,
      isFullCode: false,
    };
  },
  mounted() {
    for (let i = 0; i < this.codeLength; i++) {
      this.code.push("");
    }
    this.$refs.code[0].focus();
  },
  computed: {
    emptyItems() {
      return this.code.filter((item) => !item.length);
    },
    isSuccess() {
      return (
        this.code.length === this.codeLength &&
        !this.emptyItems.length &&
        !this.hasError &&
        this.isFullCode
      );
    },
  },
  watch: {
    isSuccess() {
      if (this.isSuccess) {
        this.dispatchCode(this.code.join(""));
      }
    },
  },
  methods: {
    enterValue(index) {
      this.hasError = false;
      if (!this.code[index - 1].match(/[\d]/)) {
        this.code.splice(index - 1, 1, "");
        return false;
      }
      if (index < this.codeLength) {
        this.$refs.code[index].focus();
      } else {
        this.isFullCode = true;
        this.$refs.code[index - 1].blur();
      }
    },
    clearValue(index) {
      this.isFullCode = false;
      this.mobileHasError = false;
      this.code.splice(index - 1, 1, "");
    },
    getCodeAgainMobile() {
      this.code = [];
      this.validResponse = true;
      this.getCodeAgain();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 500px;
  height: 100vh;
  padding: 16px 16px 10rem;
  margin: 0 auto;
  &__content {
    width: 100%;
  }

  &__link {
    margin-bottom: 24px;
  }
  &__title {
    margin-bottom: 12px;
  }
  &__description {
    margin-bottom: 12px;
  }
  &__phone {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: $mainBlack;
    text-decoration: none;
  }
  &__code {
    margin-bottom: 16px;
  }
  &__code-wrapper {
    position: relative;
    .confirm__code {
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;
      margin: 0 auto;
      &-item {
        width: 40px;
        height: 48px;
        background-color: $bckgrnd;
        border: transparent;
        outline: none;
        border-radius: 8px;
        font-family: $fontPrimary;
        font-weight: 500;
        font-size: 20px;
        padding-left: 14px;
        &:focus {
          border: 1px solid $select;
        }
        &.success {
          border: 1px solid #28b411;
        }
        &.error {
          background: #fff2f2;
          border: 1px solid #ed2121;
        }
      }
    }
    .customErrors {
      left: 47px;
    }
  }
}
</style>
