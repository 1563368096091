<template>
  <div class="confirm" v-loading="loading">
    <TimeslotConfirmationTimer :duration="duration" @close="$emit('close')" />
    <div v-if="!isMobile" class="font1-24-30 confirm__title">
      Подтверждение переноса
    </div>
    <div class="font2-14-18 confirm__description">
      Таймслот будет перенесен на:
    </div>
    <RequestDateTime
      :timeslot="timeslot.timeslots[0]"
      class="confirm__timeslot"
    />
    <div class="form__buttons">
      <CancelButton
        title="Отказаться"
        class="form__buttons-item"
        @button-clicked="$emit('close')"
      />
      <MainButton
        title="Подтвердить"
        class="form__buttons-item"
        @button-clicked="confirmReschedule"
      />
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/UI/controls/buttons/MainButton";
import RequestDateTime from "@/components/timeslots/common/RequestDateTime";
import TimeslotConfirmationTimer from "@/components/timeslots/common/TimeslotConfirmationTimer";
import { diffDefaultFromNow } from "@/core";

import Api from "@/api";
import CancelButton from "@/components/UI/controls/buttons/CancelButton.vue";
export default {
  name: "RescheduleConfirmForm",
  components: {
    CancelButton,
    MainButton,
    RequestDateTime,
    TimeslotConfirmationTimer,
  },
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
    oldId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    duration() {
      return diffDefaultFromNow(this.timeslot.confirm_until);
    },
  },
  methods: {
    async confirmReschedule() {
      this.loading = true;
      let request = new FormData();
      request.append("timeslot_id", this.timeslot.timeslots[0].timeslotId);
      request.append("terminal_id", this.timeslot.timeslots[0].terminal_id);
      request.append("old_timeslot_id", this.oldId);
      try {
        await Api.timeslots.submitRescheduleTimeslot(request);
        this.$emit("confirmed");
      } catch (error) {
        this.errorInformer(error.data[0].message, 65);
        this.$emit("close");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  &__title {
    margin-top: 24px;
    color: $mainBlack;
  }
  &__description {
    margin-top: 16px;
    color: $greyText;
  }
  &__timeslot {
    margin-top: 8px;
  }
  .form__buttons {
    margin-top: 32px;
  }
}
</style>
