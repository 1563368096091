<template>
  <section class="wrapper">
    <div class="document">
      <div class="title">
        <h1>Публичная оферта</h1>
      </div>
      <div class="date">
        <div>г. Москва</div>
        <div>Редакция от 19.07.2024 г.</div>
      </div>

      <div class="block">
        <div class="name">1. Термины</div>
        <p class="item">
          1.1. В целях единого толкования и понимания, нижеприведенные термины
          используются в следующем значении:
        </p>
        <p class="subitem">
          1.1.1. Акцепт оферты - полное и безоговорочное принятие Пользователем
          условий настоящей Оферты путем совершения любых действий в Сервисе,
          включая, но не ограничиваясь, оплатой услуг, регистрацией,
          использованием функционала Сервиса.
        </p>
        <p class="subitem">
          1.1.2. Договор - возмездное соглашение между Исполнителем и
          Пользователем, заключенное посредством Акцепта Оферты (далее -
          «Договор» или «Оферта», в зависимости от контекста).
        </p>
        <p class="subitem">
          1.1.3. Пользователь - физическое или юридическое лицо, заключившее с
          Исполнителем Договор на условиях, содержащихся в настоящей Оферте.
        </p>
        <p class="subitem">
          1.1.4. Сервис - веб-сайт, расположенный в сети интернет по адресу
          <span :href="baseUrl" target="_blank" class="link"
            >www.zernovozam.ru</span
          >, и /или/ мобильное приложение “Зерновозы”, доступное для скачивания
          в Google Play и App Store. Лицензионное соглашение на использование
          Сервисов доступно по адресу
          <span
            :href="`${baseUrl}/license-agreement`"
            target="_blank"
            class="link"
            >{{ `${baseUrl}/license-agreement` }}</span
          >. Сервисы являются интерфейсами для работы с электронной очередью ПК
          "Подскок" (программный комплекс "Подскок") и являются его составной
          частью.
        </p>
        <p class="subitem">
          1.1.5 Авансовый счет – виртуальный счет Пользователя в Сервисе, на
          который Пользователь вносит денежные средства для последующего
          использования на оплату доступных цифровых услуг.
        </p>
        <p class="subitem">
          1.1.6. Услуги – цифровые услуги, предоставляемые Исполнителем
          Пользователю через Сервис и оплачиваемые за счет средств, внесенных на
          Авансовый счет.
        </p>
        <p class="subitem">
          1.1.7. Пользователь без электронной регистрации — Пользователь,
          который предоставляет транспортные услуги и взаимодействует с
          Исполнителем напрямую в Пункте оцифровки документов без использования
          Сервисов.
        </p>
        <p class="subitem">
          1.1.8. Пункт оцифровки документов — место, арендуемое Исполнителем,
          где менеджеры переводят информацию с бумажных носителей и личных
          документов в цифровой вид для регистрации в системах учета стивидоров.
        </p>
      </div>

      <div class="block">
        <div class="name">2. Предмет договора и общие положения</div>
        <p class="item">
          2.1. Исполнитель обязуется предоставить Пользователю бесплатный доступ
          к Сервису, при этом некоторые функции внутри Сервиса предоставляются
          за вознаграждение согласно условиям, предусмотренным настоящей
          публичной офертой. Сервисы являются интерфейсами для работы с
          электронной очередью ПК "Подскок" (программный комплекс "Подскок").
        </p>
        <p class="subitem">
          2.1.1. Исполнитель также предоставляет услуги перевозчикам без
          электронной регистрации и/или без использования Сервисов, включающие
          регистрацию в Пункте оцифровки документов, оцифровку документов и
          добавление в общий реестр транспорта. Для оформления пропусков на
          территорию стивидоров собираются и обрабатываются персональные данные
          водителей (с их согласия), включая цифровые копии водительского
          удостоверения и паспорта, а также данные с этих документов.
        </p>
        <p class="subitem">
          2.1.2. Текст настоящей Оферты размещен и доступен для ознакомления по
          следующему адресу:
          <span
            :href="`${baseUrl}/public-oferta`"
            target="_blank"
            class="link"
            >{{ `${baseUrl}/public-oferta` }}</span
          >.
        </p>
        <p class="item">
          2.2. Пользователь вправе использовать Сервис путем эксплуатации его
          функциональных возможностей исключительно для собственных нужд.
        </p>
        <p class="subitem">
          2.2.1. Пользователь соглашается с условиями Политики обработки
          персональных данных, доступной по адресу:
          <span
            :href="`${baseUrl}/privacy-policy`"
            target="_blank"
            class="link"
            >{{ `${baseUrl}/privacy-policy` }}</span
          >.
        </p>
        <p class="item">2.3. Пользователю запрещается:</p>
        <ul class="list">
          <li>
            копировать или изменять код Сервиса; создавать производные
            программы;
          </li>
          <li>
            проникать в программное обеспечение с целью получения кода Сервиса;
          </li>
          <li>
            осуществлять продажу, сдачу в аренду, передачу третьим лицам
            каких-либо прав на Сервис, а также модифицировать Сервис, в том
            числе с целью получения несанкционированного доступа к нему.
          </li>
        </ul>
        <p class="item">
          2.4. Право и способ использования, прямо не предусмотренные настоящей
          Офертой, не считаются предоставленными Пользователю.
        </p>
        <p class="item">
          2.5. В случае нарушения Пользователем условий настоящей Оферты, в том
          числе условий об оплате настоящего Договора, Исполнитель имеет право
          блокировать доступ Пользователя полностью либо частично к Сервису или
          услугам Сервиса.
        </p>
        <p class="item">2.6. Лицензия имеет следующие характеристики:</p>
        <p class="subitem">2.6.1. Вид лицензии: неисключительная.</p>
        <p class="subitem">
          2.6.2. Право на заключение сублицензионных договоров Пользователем: не
          предусмотрено.
        </p>
        <p class="subitem">
          2.6.3. Лицензия действует на территории Российской Федерации,
          республики Беларусь и республики Казахстан.
        </p>
        <p class="item">
          2.7. Пользователь получает доступ к Сервису через доступ к
          персональному аккаунту.
        </p>
        <p class="item">
          2.8. Пользователь обязуется обновлять данные, предоставленные при
          регистрации, в случае их изменения; обеспечивать сохранность данных от
          доступа третьих лиц; не передавать свой номер мобильного телефона
          третьим лицам для входа в Сервис.
        </p>
        <p class="item">
          2.9. При первом входе в Сервис Пользователь указывает свой номер
          мобильного телефона, на который высылается SMS с кодом доступа для
          регистрации. При повторном входе Пользователь вводит свой номер
          телефона и код доступа из новой SMS, после чего происходит загрузка
          его профиля. Пользователь самостоятельно обеспечивает
          конфиденциальность своего номера телефона и несет ответственность за
          его безопасность.
        </p>
        <p class="item">
          2.10. Стороны не вправе ссылаться на незаключенность настоящего
          Договора при встречном предоставлении в порядке статьи 432 ГК РФ.
        </p>
        <p class="item">
          2.11. Новая редакция Договора (Оферты) вступает в силу в день ее
          опубликования.
        </p>
      </div>

      <div class="block">
        <div class="name">3. Акцепт оферты</div>
        <p class="item">
          3.1. Акцептом Оферты является факт оплаты Пользователем Договора;
          конклюдентные действия Пользователя или любое взаимодействие
          Пользователя с функционалом Сервиса в любом объеме, иное
          волеизъявление, исходя из существа правоотношений Сторон. Полный текст
          Лицензионного соглашения доступен по адресу:
          https://zernovozam.ru/doc/lic.pdf.
        </p>
        <p class="item">
          3.2. До Акцепта настоящей Оферты Пользователь обязуется ознакомиться с
          ее содержанием. При наличии каких-либо сомнений в толковании условий
          настоящей Оферты Пользователь вправе до совершения Акцепта обратиться
          к Исполнителю с письменным запросом, в том числе посредством сети
          интернет, иным способом, исходя из существа правоотношений. В ином
          случае Пользователь не имеет права ссылаться на незнание Оферты, если
          иное не установлено императивными нормами законодательства РФ.
        </p>
        <p class="item">
          3.3. Исполнитель вправе по своему усмотрению создавать, изменять или
          отменять условия настоящей Оферты, если иное не предусмотрено
          императивными нормами законодательства РФ.
        </p>
        <p class="item">
          3.4. Совершая Акцепт, Пользователь подтверждает свою полную
          дееспособность. Акцепт не может считаться совершенным в случае, если
          совершен недееспособным лицом, а также лицом, не достигшим возраста 18
          лет (исключая случай эмансипации). Риск совершения Акцепта
          недееспособным лицом или лицом, не достигшим совершеннолетия, лежит на
          законных представителях такого лица.
        </p>
        <p class="item">
          3.5. Исполнитель сообщает, что данная Оферта действует совместно с
          Политикой конфиденциальности и Согласием на получение рекламы.
          Принимая условия настоящей Оферты, Пользователь также принимает все
          условия вышеперечисленных документов и подтверждает, что ознакомился с
          ними.
        </p>
        <p class="item">
          3.6. В случае если вышеперечисленные документы противоречат Оферте,
          приоритет следует отдавать условиям настоящей Оферты.
        </p>
        <p class="item">
          3.7. Оплата Договора Пользователем производится посредством сервиса
          приема платежей (платежной системы) в соответствии с информацией,
          представленной на соответствующих страницах (разделах) Сервиса
          Исполнителя. Пользователь Акцептом настоящей Оферты подтверждает
          также, что ознакомился с Офертой, Политикой конфиденциальности и иными
          документами сервиса приема платежей (платежной системы).
        </p>
      </div>

      <div class="block">
        <div class="name">4. Финансовые условия</div>
        <p class="item">
          4.1. Стоимость каждой услуги, выполняемой в соответствии с настоящей
          Офертой, определяется Исполнителем в одностороннем порядке и
          сообщается Пользователю посредством Сервиса.
        </p>
        <p class="item">
          4.2. Исполнитель вправе в одностороннем порядке изменять стоимость
          услуг, если такое изменение стоимости не нарушит императивных норм
          Гражданского кодекса РФ.
        </p>
        <p class="item">
          4.3. Компания предоставляет Пользователю возможность вносить денежные
          средства на Авансовый счет на Сервисе для последующего использования
          данных средств на оплату доступных услуг.
        </p>
        <p class="item">
          4.4. Внесение денежных средств на Авансовый счет осуществляется
          Пользователем путем использования различных методов оплаты, доступных
          в Сервисе.
        </p>
        <p class="item">
          4.5. Списание денежных средств с Авансового счета производится по мере
          использования Пользователем услуг в Сервисе. Стоимость каждой услуги
          указывается в Сервисе и автоматически списывается с Авансового счета
          Пользователя при заказе услуги.
        </p>
        <p class="item">
          4.6. Оформление и обмен любыми документами по настоящему Договору
          (включая, но не ограничиваясь, акты об оказании услуг, акты сверки)
          осуществляется в электронном виде, с использованием электронного
          документооборота (ЭДО). <br />Документы на бумажном носителе,
          подписанные и заверенные печатью Исполнителя, предоставляются
          Пользователю по письменному запросу, направленному на электронную
          почту buh@zernovozam.ru (при необходимости).
        </p>
        <p class="item">4.7. Механизм возврата денежных средств</p>
        <p class="subitem">
          4.7.1. Пользователь вправе запросить возврат неиспользованных денежных
          средств, находящихся на Авансовом счете, в любое время, направив
          соответствующий запрос на электронную почту Исполнителя
          buh@zernovozam.ru.
        </p>
        <p class="subitem">
          4.7.2. Возврат денежных средств осуществляется в течение 10 (десяти)
          рабочих дней с момента получения запроса от Пользователя, при условии
          отсутствия задолженностей перед Исполнителем.
        </p>
        <p class="subitem">
          4.7.3. Возврат денежных средств производится тем же способом, которым
          были внесены средства на Авансовый счет, если иное не будет
          согласовано с Пользователем.
        </p>
        <p class="subitem">
          4.7.4. В случае если средства были внесены Пользователем через
          платежные системы, все комиссии за перевод средств при возврате
          денежных средств оплачивает Пользователь, за исключением случаев,
          когда возврат обусловлен ошибкой или неисправностью Сервиса.
        </p>
        <p class="subitem">
          4.7.5. Исполнитель вправе отказать в возврате денежных средств в
          следующих случаях:
        </p>
        <ul class="list">
          <li>если Пользователь нарушил условия настоящей Оферты;</li>
          <li>
            если запрашиваемая сумма уже была использована для оплаты услуг
            Сервиса;
          </li>
          <li>
            если запрос на возврат поступил более чем через 30 календарных дней
            с момента последнего использования Сервиса.
          </li>
        </ul>
        <p class="subitem">
          4.7.6. В случае отказа в возврате денежных средств, Исполнитель обязан
          предоставить Пользователю письменное объяснение причин отказа в
          течение 5 (пяти) рабочих дней с момента получения запроса на возврат.
        </p>
        <p class="subitem">
          4.7.7. Пользователь обязуется предоставить Исполнителю всю необходимую
          информацию для проведения возврата денежных средств, включая, но не
          ограничиваясь: номер мобильного телефона, идентификационные данные,
          реквизиты банковского счета или данные платежной системы, а также
          подтверждение оплаты.
        </p>
        <p class="subitem">
          4.7.8. Возврат денежных средств не производится в случае, если
          Пользователь завершил использование Сервиса и удалил свой аккаунт.
        </p>
        <p class="item">
          4.8. Услуги, предоставляемые в Пунктах оцифровки документов:
        </p>
        <p class="subitem">
          4.8.1. Пользователи без электронной регистрации обязаны оплачивать
          услуги в безналичном виде на месте в Пункте оцифровки документов.
        </p>
        <p class="subitem">
          4.8.2. Стоимость услуг в Пункте оцифровки документов определяется
          Исполнителем и сообщается Пользователю на месте.
        </p>
        <p class="subitem">
          4.8.3. Исполнитель вправе в одностороннем порядке изменять стоимость
          услуг, предоставляемых в Пунктах оцифровки документов, если такое
          изменение стоимости не нарушает императивные нормы Гражданского
          кодекса РФ.
        </p>
        <p class="subitem">
          4.8.4. В случае отказа от использования услуг, оплаченных в Пункте
          оцифровки документов, возврат денежных средств осуществляется в
          соответствии с механизмом возврата денежных средств, указанным в п.
          4.7 настоящей Оферты.
        </p>
      </div>

      <div class="block">
        <div class="name">5. Срок действия договора</div>
        <p class="item">
          5.1. Настоящий Договор действует до конца календарного года, в котором
          он был заключён.
        </p>
        <p class="item">
          5.2. Настоящий Договор автоматически пролонгируется на период
          аналогичный сроку его действия, если ни одна из Сторон не заявит в
          письменной форме за 15 календарных дней до даты его истечения о своем
          намерении расторгнуть Договор.
        </p>
        <p class="item">
          5.3. Период, исчисляемый со дня заключения Договора Сторонами и до
          даты прекращения его действия по любым обстоятельствам, признается
          сроком действия Договора.
        </p>
        <p class="item">
          5.4. Расторжение (прекращение действия) настоящего Договора означает,
          в том числе, прекращение действия всех приложений, дополнительных
          соглашений и иных документов к нему, если иное не предусмотрено в
          соответствующих приложениях и дополнительных соглашениях (иных
          документах) к Договору.
        </p>
        <p class="item">
          5.5. Каждая из Сторон вправе в одностороннем внесудебном порядке
          отказаться от Договора, уведомив другую Сторону за 10 календарных дней
          до отказа. Договор считается прекращенным по истечении указанного
          срока (включительно).
        </p>
        <p class="item">5.6. Исполнитель вправе:</p>
        <p class="subitem">
          5.6.1. Временно приостановить исполнение Договора по техническим,
          технологическим или иным причинам, препятствующим его исполнению, на
          время устранения таких причин.
        </p>
        <p class="subitem">
          5.6.2. Приостановить исполнение Договора в одностороннем внесудебном
          порядке при нарушении Пользователем иных обязательств, принятых в
          соответствии с Офертой в порядке ст. 328 Гражданского кодекса РФ.
        </p>
      </div>

      <div class="block">
        <div class="name">6. Ответственность сторон</div>
        <p class="item">
          6.1. Стороны несут ответственность за последствия собственных действий
          в соответствии с действующим законодательством Российской Федерации.
        </p>
        <p class="item">
          6.2. Вся информация предоставляется в исходном виде, без гарантий
          полноты или своевременности, без иных, явно выраженных или
          подразумеваемых гарантий. Доступ к Сервису, а также использование его
          содержимого осуществляются исключительно по усмотрению и под
          ответственность Пользователя.
        </p>
        <p class="item">
          6.3. Пользователь осознает, что все материалы Сервиса или любая их
          часть могут сопровождаться рекламой. Пользователь согласен с тем, что
          Исполнитель не несет какой-либо ответственности и не имеет каких-либо
          обязательств в связи с такой рекламой.
        </p>
        <p class="item">
          6.4. Пользователь заверяет Исполнителя о достоверности предоставляемой
          информации, включая персональные данные, и соглашается на их обработку
          для целей оформления пропусков на территорию зоны таможенного
          контроля.
        </p>
        <p class="item">
          6.5. Пользователь обязуется не использовать программное обеспечение (и
          иной код) для автоматизированного сбора информации и (или)
          взаимодействия с Сервисом, в ином случае Пользователь обязан
          возместить Исполнителю убытки, причиненные указанными действиями, в
          полном объеме.
        </p>
        <p class="item">
          6.6. Пользователь при взаимодействии с Исполнителем обязуется не
          распространять информацию, которая направлена на пропаганду войны,
          разжигание национальной, расовой или религиозной ненависти и вражды, а
          также иной информации, за распространение которой предусмотрена
          уголовная или административная ответственность.
        </p>
      </div>

      <div class="block">
        <div class="name">7. Разрешение споров</div>
        <p class="item">
          7.1. Все споры и разногласия, которые могут возникнуть между сторонами
          по настоящему Договору, будут разрешаться в соответствии с
          законодательством Российской Федерации.
        </p>
      </div>

      <div class="block">
        <div class="name">8. Форс-мажорные обстоятельства</div>
        <p class="item">
          8.1. Стороны освобождаются от ответственности за частичное или полное
          неисполнение обязательств по Договору, если это неисполнение явилось
          следствием возникшей после заключения настоящего Договора
          непреодолимой силы, которой Стороны не могли предвидеть или
          предотвратить.
        </p>
        <p class="item">
          8.2. При наступлении обстоятельств, указанных в настоящем разделе,
          каждая Сторона должна в течении 2 календарных дней известить о них
          другую Сторону.
        </p>
        <p class="item">
          8.3. Извещение должно содержать данные о характере обстоятельств, а
          также официальные документы, удостоверяющие наличие этих обстоятельств
          и, по возможности, дающие оценку их влияния на исполнение Стороной
          своих обязательств по настоящему Договору.
        </p>
        <p class="item">
          8.4. В случаях наступления обстоятельств, предусмотренных настоящим
          разделом, срок выполнения Стороной обязательств по настоящему Договору
          отодвигается соразмерно времени, в течение которого действуют эти
          обстоятельства и их последствия.
        </p>
        <p class="item">
          8.5. Если обстоятельства, указанные в настоящем разделе, и их
          последствия продолжают действовать более 1 календарного месяца,
          Стороны проводят дополнительные переговоры для выявления приемлемых
          альтернативных способов исполнения настоящего Договора.
        </p>
      </div>

      <div class="block">
        <div class="name">9. Заключительные положения</div>
        <p class="item">
          9.1. В случае признания недействительным какого-либо положения
          настоящего Договора, остальные его положения не утрачивают свою силу.
        </p>
        <p class="item">
          9.2. Обо всех изменениях своего местонахождения или банковских
          реквизитов, а равно других обстоятельств, имеющих значение для
          надлежащего исполнения настоящего Договора, Стороны обязаны извещать
          друг друга незамедлительно.
        </p>
        <p class="item">
          9.3. В случае изменения каких-либо сведений о Стороне в период
          действия настоящего Договора, такая Сторона обязуется уведомить об
          этом другую Сторону в течение 5 (пять) календарных дней, в противном
          случае, все связанные с отсутствием такого уведомления риски Сторона
          несет самостоятельно.
        </p>
        <p class="item">
          9.4. Принимая настоящую Оферту, Стороны подтверждают, что:
        </p>
        <p class="subitem">
          9.4.1. ознакомились с Договором и им понятны значения используемых в
          Договоре терминов, слов и выражений согласно нормативно-правовому
          определению или толкованию, указанному в Договоре;
        </p>
        <p class="subitem">
          9.4.2. заключают Договор добровольно и согласны с его условиями;
        </p>
        <p class="subitem">
          9.4.3. имеют право на самостоятельное совершение сделки (в частности,
          заключение Договора) и действий, предусмотренных Договором.
        </p>
      </div>

      <div class="block">
        <div class="name">10. Реквизиты Исполнителя</div>
        <div class="requisites">
          <div class="requisites__item">Полное наименование организации</div>
          <div class="requisites__item">
            ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ПОРТ ТРАНЗИТ»
          </div>
        </div>
        <div class="requisites">
          <div class="requisites__item">Сокращенное наименование</div>
          <div class="requisites__item">ООО «ПОРТ ТРАНЗИТ»</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">Юридический адрес</div>
          <div class="requisites__item">
            121205, город Москва, Внутренний территориальный городской
            муниципальный округ Можайский, тер. Сколково инновационного центра,
            б-р Большой, д. 42, стр. 1, пом. 138
          </div>
        </div>
        <div class="requisites">
          <div class="requisites__item">Адрес обособленного подразделения</div>
          <div class="requisites__item">
            353960, Россия, Краснодарский край, г. Новороссийск, с. Цемдолина,
            ул. Ленина, 7«М»
          </div>
        </div>
        <div class="requisites">
          <div class="requisites__item">ИНН</div>
          <div class="requisites__item">2315190510</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">КПП</div>
          <div class="requisites__item">773101001</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">ОГРН</div>
          <div class="requisites__item">1142315005722</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">ОКПО</div>
          <div class="requisites__item">26570982</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">ОКВЭД</div>
          <div class="requisites__item">62.01</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">Генеральный директор</div>
          <div class="requisites__item">Незамутдинов Ян Русланович</div>
        </div>
        <br />
        <div class="requisites">
          <div class="requisites__item">Расчетный счет</div>
          <div class="requisites__item">40702810400750000100</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">Банк получателя</div>
          <div class="requisites__item">
            КБ «КУБАНЬ КРЕДИТ» ООО, г.КРАСНОДАР
          </div>
        </div>
        <div class="requisites">
          <div class="requisites__item">БИК</div>
          <div class="requisites__item">040349602</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">Корреспондентский счет</div>
          <div class="requisites__item">30101810200000000722</div>
        </div>
        <br />
        <div class="requisites">
          <div class="requisites__item">Расчетный счет</div>
          <div class="requisites__item">40702810530000018368</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">Банк получателя</div>
          <div class="requisites__item">
            Краснодарское отделение №8619 ПАО Сбербанк
          </div>
        </div>
        <div class="requisites">
          <div class="requisites__item">БИК</div>
          <div class="requisites__item">040349602</div>
        </div>
        <div class="requisites">
          <div class="requisites__item">Корреспондентский счет</div>
          <div class="requisites__item">30101810100000000602</div>
        </div>
        <br />
        <div class="requisites">
          <div class="requisites__item">E-mail</div>
          <div class="requisites__item">buh@zernovozam.ru</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import urlMixin from "@/views/Documents/urlMixin";
export default {
  name: "PublicOferta",
  metaInfo: {
    title: "ЭО Порт транзит | Публичная оферта",
  },
  mixins: [urlMixin],
};
</script>

<style lang="scss" scoped>
@import "./styles";
.document .date {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 16px;
  font-size: 18px;
}
.document .requisites {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 16px;
  &__item {
    width: 50%;
    &:first-child {
      color: $greyText;
      text-align: right;
    }
    &:last-child {
      padding-right: 100px;
    }
  }
}
</style>
