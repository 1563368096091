<template><component :is="currentView" /></template>

<script>
import DesktopConfirmView from "@/views/AuthViews/confirmView/DesktopConfirmView";
import MobileConfirmView from "@/views/AuthViews/confirmView/MobileConfirmView";

export default {
  name: "ConfirmView",
  metaInfo: {
    title: "ЭО Порт транзит | Авторизация",
  },
  components: { DesktopConfirmView, MobileConfirmView },
  computed: {
    currentView() {
      return this.isMobile ? MobileConfirmView : DesktopConfirmView;
    },
  },
};
</script>
