import HomeMessage from "@/components/home/HomeMessage";
import WorkBanner from "@/components/home/WorkBanner";
import HomeTimeslots from "@/components/home/HomeTimeslots/HomeTimeslots";
import ExpBanner from "@/components/home/ExpBanner";
import { mapGetters } from "vuex";
import { GET_PROFILE } from "@/store/actions";
import { nrzBanner, expBanner } from "@/constants/main-banners-content";

import Api from "@/api";

export default {
  components: {
    HomeMessage,
    WorkBanner,
    HomeTimeslots,
    ExpBanner,
  },
  async mounted() {
    try {
      // запрос сообщения (для верхнего баннера)
      const messages = await Api.home.getMessage();
      this.message = messages.data.length ? messages.data[0] : null;
      // запрос активных таймслотов
      const timeslots = await Api.home.getTimeslots();
      this.timeslots = timeslots.data;
    } catch (error) {
      this.openConnectionModal = true;
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: true,
      message: {
        text: "",
        date: "",
      },
      timeslots: [],
      openConnectionModal: false,
      nrzBanner,
      expBanner,
    };
  },
  computed: {
    ...mapGetters({
      user: GET_PROFILE,
    }),
    userName() {
      if (!this.isMobile) {
        if (this.user && this.user.first_name) {
          return `, ${this.user.first_name}`;
        }
        return "";
      }
      if (this.user && this.user.first_name) {
        return this.user.first_name;
      }
      return "";
    },
  },
};
