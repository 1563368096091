<template>
  <el-form :model="terminalForm" class="terminalSelection">
    <div class="terminalSelection__terminal">
      <span class="font1-20-24 title">{{ currentTitle }}</span>

      <div class="terminal">
        <el-form-item class="terminal__item">
          <CustomSelect
            :model="terminalForm.city_id"
            placeholder="Регион"
            :options="citiesOptions"
            @select="setCity"
          />
        </el-form-item>

        <el-form-item class="terminal__item">
          <CustomSelect
            :model="terminalForm.terminal_id"
            placeholder="Терминал"
            :options="terminalOptions"
            :disabled="selectDisabled"
            @select="setTerminal"
          />
        </el-form-item>
      </div>

      <el-collapse-transition>
        <div
          v-if="terminalInstruction"
          v-html-safe="terminalInstruction"
          class="instruction"
        />
      </el-collapse-transition>

      <div v-if="terminalForm.terminal_id" class="price">
        <div class="font2-14-18 price__title">Цена за 1 таймслот</div>

        <div class="font1-20-24 price__value">
          {{
            currentTerminal.timeslot_price_rub
              ? `${formattedNumberRu(currentTerminal.timeslot_price_rub)} ₽`
              : "Бесплатно"
          }}
        </div>
      </div>

      <div v-if="terminalForm.terminal_id" class="balance">
        <UserBalance
          :per-timeslot="currentTerminal.timeslot_price_rub"
          @payment="$router.push({ name: isMobile ? 'balance' : 'cabinet' })"
        />
      </div>
    </div>

    <div class="terminalSelection__buttons">
      <MainButton
        v-if="isMobile"
        title="Продолжить"
        :disabled="!canContinue"
        class="terminalSelection__buttons-next"
        @button-clicked="next"
      />
      <SecondaryButton
        v-else
        class="terminalSelection__buttons-next"
        title="Продолжить"
        :disabled="!canContinue"
        @button-clicked="next"
      >
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.33341 12.3334L6.66675 7.00008L1.33341 1.66675"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </SecondaryButton>
    </div>
  </el-form>
</template>

<script>
import { formattedNumberRu } from "@/core";
import { GET_TERMINAL_CITIES, SET_LOADING } from "@/store/actions";
import { mapGetters, mapMutations } from "vuex";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";
import CustomSelect from "@/components/UI/controls/form/CustomSelect.vue";
import SecondaryButton from "@/components/UI/controls/buttons/SecondaryButton.vue";
import UserBalance from "./components/UserBalance.vue";

import API from "@/api";

export default {
  name: "TerminalSelection",
  components: {
    MainButton,
    CustomSelect,
    SecondaryButton,
    UserBalance,
  },
  data() {
    return {
      formattedNumberRu,
      terminalOptions: [],
      clearTerminal: false,
      terminalForm: {
        city_id: "",
        terminal_id: "",
      },
      terminalInstruction: "",
    };
  },
  computed: {
    ...mapGetters({
      citiesOptions: GET_TERMINAL_CITIES,
    }),
    selectDisabled() {
      return !this.terminalForm.city_id;
    },
    currentTitle() {
      return this.isMobile ? "Получение таймслотов" : "Выбор терминала";
    },
    buttonStyle() {
      return this.isMobile ? "mobile" : "next";
    },
    canContinue() {
      return this.terminalForm.city_id && this.terminalForm.terminal_id;
    },
    currentTerminal() {
      return this.terminalOptions.find(
        (item) => item.id === this.terminalForm.terminal_id
      );
    },
  },
  methods: {
    ...mapMutations({
      setLoading: SET_LOADING,
    }),
    setCity(id) {
      this.terminalForm.terminal_id = "";
      this.terminalForm.city_id = id;
      this.terminalInstruction = "";
      this.getTerminalOptions();
    },
    async getTerminalOptions() {
      this.setLoading(true);
      try {
        const { data } = await API.terminals.getTerminals({
          city_id: this.terminalForm.city_id,
        });
        this.terminalOptions = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.setLoading(false);
      }
    },
    async setTerminal(id) {
      this.terminalForm.terminal_id = id;
      // get instruction text for current terminal
      this.setLoading(true);
      try {
        const { data } = await API.terminals.getTerminalInstruction({
          terminal_id: id,
        });
        this.terminalInstruction = data.unload_text;
      } catch (error) {
        console.log(error);
      } finally {
        this.setLoading(false);
      }
    },
    next() {
      this.$emit("next", {
        terminal: this.currentTerminal,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.terminalSelection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  @media (max-width: 1279px) {
    padding-top: 120px;
    padding-bottom: 13.6rem;
  }

  &__terminal {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      font-weight: 500;
      color: $mainBlack;
    }

    .terminal {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      @media (max-width: 1279px) {
        flex-direction: column;
      }
      &__item {
        width: 50%;
        margin-bottom: 0;
        @media (max-width: 1279px) {
          width: 100%;
        }
      }
    }

    .instruction {
      font-size: 16px;
      color: $mainBlack;
      :deep(a) {
        color: $special;
      }
      :deep(ol) {
        padding-left: 16px;
        counter-reset: count 0;
        & li {
          counter-increment: count 1;

          &::marker {
            content: counter(count, decimal) ". ";
            color: $special;
            font-weight: 700;
          }
        }
      }
      :deep(ul) {
        padding-left: 16px;
        & li {
          list-style-type: disc;
        }
      }
    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 58px;
      border-top: 1px dashed $greyBanner;
      border-bottom: 1px dashed $greyBanner;
      &__title {
        font-weight: 500;
        color: $textSecondary;
      }
      &__value {
        font-weight: 500;
        color: $mainBlack;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    @media (max-width: 1279px) {
      margin-top: 20px;
    }
    &-next {
      width: 200px;
      @media (max-width: 1279px) {
        width: 100%;
      }
    }
  }
}
</style>
