// auth
export const auth = {
  dispatchPhone: "/native/auth/send-sms",
  checkTerms: "/native/legal/check-terms-acceptance",
  acceptTerms: "/native/legal/accept-terms",
  getToken: "/native/auth/get-token",
};

export const home = {
  getMessage: "/native/public/system-messages",
};

export const timeslots = {
  getTimeslots: "/native/timeslots/list",
  deleteTimeslot: "/native/timeslots/drop-timeslot",
  archiveTimeslots: "/native/timeslots/history",
  rescheduleTimeslot: "/native/timeslots/request-move",
  submitRescheduleTimeslot: "/native/timeslots/confirm-move",
  getCarrierAutofill: "/native/autocomplete/get-carriers-hints",
  checkCarrierInn: "/native/public/check-carrier-org",
  requestTimeslots: "/native/timeslots/request-timeslots",
  getAutofillList: "/native/autocomplete/get-timeslots-hints",
  submitTimeslot: "/native/timeslots/confirm-timeslot",

  payTimeslots: "/native/timeslots/purchase",
};

// profile
export const profile = {
  getProfile: "/native/profile/get-profile",
  uploadAvatar: "/native/profile/upload-picture",
  updateProfile: "/native/profile/change-name",
  deleteProfileData: "/native/profile/delete-user-data",
  deleteAccount: "/native/profile/delete-account",
  getUserTrucks: "/native/profile/get-user-trucks",
  getGlonassPhone: "/native/legal/glonass-dispatcher",
  checkBillInn: "/native/public/check-inn",
  createBillRequest: "/native/wallet/bill-create",
  sendBillEmail: "/native/wallet/bill-send",
  downloadBill: "/native/wallet/bill-download",
  makeSbpPayment: "/native/wallet/make-yoo-cash-payment-sbp",
  makeYooCashPayment: "/native/wallet/make-yoo-cash-payment",
  accountOperations: "/native/wallet/operations",
};

// cities
export const cities = {
  getCities: "/native/list/cities",
};

// terminals
export const terminals = {
  getTerminals: "/native/list/terminals",
  getTerminalInstruction: "/native/public/terminal-text",
  getTerminalSettings: "/native/public/terminal-info",
  getTerminalExporters: "/native/list/exporters",
  getTerminalCultures: "/native/list/cultures",
  getExporterSuppliers: "/native/list/suppliers",
};

// справочники всякой всячины
export const handbook = {
  getTruckTypes: "/native/list/truck-types",
  getAxisCount: "/native/list/axis-counts",
};

// statistics
export const statistics = {
  getStatuses: "/native/terminal-stats/statuses",
  getStatistics: "/native/terminal-stats/list",
};

// contacts
export const contacts = {
  getContacts: "/native/legal/get-company-info",
  sendForm: "/native/public/log-contact-form",
};

// unpaid-timeslots
export const unpaid = {
  getTrucksList: "/native/user-trucks/index",
  searchTruck: "/native/user-trucks/search",
  addTruckToFavorites: "/native/user-trucks/add",
  removeTruckFromFavorites: "/native/user-trucks/remove",
};
