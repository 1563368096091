<template>
  <section class="mail">
    <i class="el-icon-warning mail__icon" />
    <div class="font2-14-18 mail__text">
      Оплаченный счет отправляйте на почту
      <a :href="`mailto:${mail}`" class="mail__text-link">
        {{ mail }}
      </a>
      , с указанием номера телефона, на который зарегистрирован аккаунт
    </div>
  </section>
</template>

<script>
export default {
  name: "MailInfo",
  props: {
    mail: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.mail {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  border-radius: 8px;
  background: $errorBackground;
  &__icon {
    font-size: 20px;
    color: $error;
  }
  &__text {
    font-weight: 500;
    color: $mainBlack;
    &-link {
      font-weight: 700;
      color: $special;
    }
  }
}
</style>
