<template>
  <section class="wrapper">
    <div class="document">
      <div class="title">
        <h1>
          Лицензионное соглашение на использование мобильного приложения и сайта
          "Зерновозы" (iOS, Android, www.zernovozam.ru)
        </h1>
      </div>

      <div class="block">
        <div class="name">Оглавление</div>
        <p class="subitem">1.&nbsp;&nbsp;Предмет соглашения</p>
        <p class="subitem">2. Исключительное право на Программу и Сайт</p>
        <p class="subitem">3. Способы использования Программы и Сайта</p>
        <p class="subitem">4. Ограничения</p>
        <p class="subitem">5. Ответственность</p>
        <p class="subitem">6. Особые условия</p>
        <p class="subitem">7. Внешние сервисы и Оферта</p>
      </div>

      <div class="block">
        <p class="item">
          Перед использованием программы "Зерновозы" и сайта
          <span :href="baseUrl" target="_blank" class="link">
            www.zernovozam.ru
          </span>
          (далее по тексту — «Программа» и «Сайт» соответственно) ознакомьтесь с
          условиями настоящего лицензионного соглашения (далее по тексту —
          «Лицензия» или «Лицензионное соглашение»). Любое использование
          Программы и Сайта означает полное и безоговорочное принятие Вами
          условий настоящего Лицензионного соглашения. Если Вы не принимаете
          условия Лицензионного соглашения в полном объеме, Вы не имеете права
          использовать Программу и Сайт в каких-либо целях.
        </p>
      </div>

      <div class="block">
        <div class="name">1. Предмет соглашения</div>
        <p class="item">
          1.1. Настоящее Лицензионное соглашение заключено между пользователем
          («Пользователь») и ООО "ПОРТ ТРАНЗИТ", 121205, г. Москва, Внутренний
          территориальный городской муниципальный округ Можайский, тер. Сколково
          инновационного центра, б-р Большой, д. 42, стр. 1, пом. 138,
          являющимся правообладателем исключительных прав на Программу и Сайт
          («Правообладатель»). Лицензия устанавливает условия использования
          Пользователем Программы и Сайта.
        </p>
        <p class="item">
          1.2. Копируя Программу и устанавливая её на своё мобильное устройство,
          а также используя Сайт, Пользователь выражает своё полное и
          безоговорочное согласие со всеми условиями Лицензии, доступной по
          адресу
          <span
            :href="`${baseUrl}/license-agreement`"
            target="_blank"
            class="link"
            >{{ `${baseUrl}/license-agreement` }}.
          </span>
        </p>
        <p class="item">
          1.3. Использование Программы и Сайта разрешается только на условиях
          Лицензии. Если Пользователь не принимает условия Лицензии в полном
          объеме, Пользователь не имеет права использовать Программу и Сайт в
          каких-либо целях. Использование Программы и Сайта с нарушением
          (невыполнением) какого-либо из условий Лицензии запрещено.
        </p>
        <p class="item">
          1.4. Использование Программы и Сайта на условиях Лицензии в личных
          некоммерческих целях осуществляется безвозмездно. Использование
          Программы и Сайта на условиях и способами, не предусмотренными
          настоящей Лицензией, возможно только на основании отдельного
          соглашения с Правообладателем по цене, устанавливаемой
          Правообладателем.
        </p>
        <p class="item">
          1.5. Использование Программы и Сайта Пользователем предполагает его
          согласие на использование технической информации об устройстве,
          системе и прикладном программном обеспечении и периферийных
          устройствах Пользователя. Правообладатель вправе собирать и
          использовать технические данные и связанную информацию для улучшения
          Программы и Сайта или для предоставления услуг или технологий
          Пользователю. Правообладатель вправе использовать информацию до тех
          пор, пока такая информация находится в форме, не позволяющей
          идентифицировать Пользователя персонально.
        </p>
        <p class="item">
          1.6. Пользователь использует Программу и Сайт в рамках настоящей
          Лицензии на безвозмездной основе.
        </p>
      </div>

      <div class="block">
        <div class="name">2. Исключительное право на Программу и Сайт</div>
        <p class="item">
          2.1. Исключительное право на Программу и Сайт принадлежит
          Правообладателю.
        </p>
      </div>

      <div class="block">
        <div class="name">3. Способы использования Программы и Сайта</div>
        <p class="item">
          3.1. Правообладатель предоставляет Пользователю неисключительное право
          (простая неисключительная лицензия) использовать Программу и Сайт
          следующими способами:
        </p>
        <p class="subitem">
          3.1.1. Применять Программу по прямому функциональному назначению, в
          целях чего произвести её копирование и установку на мобильное (ые)
          устройство (ва) Пользователя. Пользователь вправе произвести установку
          Программы на неограниченное число мобильных устройств, принадлежащих
          Пользователю, при условии соблюдения условий Лицензии, размещенной по
          адресу
          <span
            :href="`${baseUrl}/license-agreement`"
            target="_blank"
            class="link"
          >
            {{ `${baseUrl}/license-agreement` }}.
          </span>
        </p>
        <p class="subitem">
          3.1.2. Использовать Сайт для доступа к функционалу, предоставляемому
          на сайте www.zernovozam.ru, в соответствии с условиями настоящей
          Лицензии.
        </p>
        <p class="item">
          3.2. Программа и Сайт должны использоваться под наименованием:
          «Зерновозы». Пользователь не вправе изменять и/или удалять
          наименование Программы и Сайта, знак охраны авторского права или иные
          указания на Правообладателя.
        </p>
        <p class="item">
          3.3. В рамках использования Программы и Сайта, Пользователь
          соглашается на сбор и обработку персональных данных для целей
          оформления пропусков на территорию стивидоров, включая цифровые копии
          водительского удостоверения и паспорта, а также данные с этих
          документов. Политика ПД доступна по адресу:
          <span
            :href="`${baseUrl}/privacy-policy`"
            target="_blank"
            class="link"
            >{{ `${baseUrl}/privacy-policy` }}</span
          >.
        </p>
      </div>

      <div class="block">
        <div class="name">4. Ограничения</div>
        <p class="item">
          4.1. За исключением использования в объемах и способами, прямо
          предусмотренными настоящей Лицензией или законодательством РФ,
          Пользователь не имеет права изменять, декомпилировать,
          дизассемблировать, дешифровать и производить иные действия с объектным
          кодом Программы и Сайта, имеющие целью получение информации о
          реализации алгоритмов, используемых в Программе и на Сайте, создавать
          производные произведения с использованием Программы и Сайта, а также
          осуществлять (разрешать осуществлять) иное использование Программы и
          Сайта без письменного согласия Правообладателя.
        </p>
        <p class="item">
          4.2. Пользователь не имеет права воспроизводить и распространять
          Программу и Сайт в коммерческих целях (за плату), в том числе в
          составе сборников программных продуктов, без письменного согласия
          Правообладателя.
        </p>
        <p class="item">
          4.3. Пользователь не имеет права распространять Программу и Сайт в
          виде, отличном от того, в котором он их получил, без письменного
          согласия Правообладателя.
        </p>
      </div>

      <div class="block">
        <div class="name">5. Ответственность</div>
        <p class="item">
          5.1. Программа и Сайт предоставляются на условиях “как есть” (as is).
          Правообладатель не предоставляет никаких гарантий в отношении
          безошибочной и бесперебойной работы Программы и Сайта или отдельных их
          компонентов, соответствия Программы и Сайта конкретным целям
          Пользователя, а также не предоставляет никаких иных гарантий, прямо не
          указанных в настоящей Лицензии.
        </p>
        <p class="item">
          5.2. В максимальной степени, допустимой действующим законодательством
          РФ, Правообладатель, равно как и его партнеры, не несет никакой
          ответственности за какие-либо прямые или косвенные последствия
          какого-либо использования или невозможности использования Программы и
          Сайта и/или ущерб, причиненный Пользователю и/или третьим лицам в
          результате какого-либо использования или неиспользования Программы и
          Сайта или отдельных их компонентов, в том числе из-за возможных ошибок
          или сбоев в их работе.
        </p>
        <p class="item">
          5.3. Все претензии, связанные с использованием/невозможностью
          использования Программы и Сайта, а также возможными фактами нарушения
          в результате использования Программы и Сайта законодательства и/или
          прав третьих лиц, должны направляться по электронной почте
          support@zernovozam.ru.
        </p>
        <p class="item">
          5.4. Настоящая Лицензия и все отношения, связанные с использованием
          Программы и Сайта, регулируются действующим законодательством РФ.
        </p>
      </div>

      <div class="block">
        <div class="name">6. Особые условия</div>
        <p class="item">
          6.1. Выполнение некоторых функций Программы и Сайта возможно только
          при наличии доступа к сети Интернет. Пользователь самостоятельно
          получает и оплачивает такой доступ на условиях и по тарифам своего
          оператора связи или провайдера.
        </p>
        <p class="item">
          6.2. Платные услуги, связанные с использованием Программы и Сайта,
          осуществляются за их рамками и регулируются соответствующими
          договорами и соглашениями между Пользователем и Исполнителем.
        </p>
      </div>

      <div class="block">
        <div class="name">7. Внешние сервисы и Оферта</div>
        <p class="item">
          7.1. Все услуги, предоставляемые через Программу и Сайт, регулируются
          отдельной Офертой, доступной по адресу
          <span
            :href="`${baseUrl}/public-oferta`"
            target="_blank"
            class="link"
            >{{ `${baseUrl}/public-oferta` }}</span
          >. Настоящее Лицензионное соглашение регулирует только права и
          обязанности, связанные с использованием Программы и Сайта, и не
          относится к предоставлению услуг, описанных в Оферте.
        </p>
        <p class="item">
          7.2. Пользователь соглашается с тем, что, используя Программу и Сайт,
          он также должен ознакомиться и согласиться с условиями Оферты,
          регулирующей предоставление услуг.
        </p>
      </div>

      <div class="block">
        <div class="name">
          8. Конфиденциальность и Политика обработки персональных данных
        </div>
        <p class="item">
          8.1. Пользователь соглашается с тем, что все предоставленные им
          персональные данные будут обрабатываться в соответствии с Политикой
          обработки персональных данных, доступной по адресу:
          <span
            :href="`${baseUrl}/privacy-policy`"
            target="_blank"
            class="link"
            >{{ `${baseUrl}/privacy-policy` }}</span
          >.
        </p>
        <br />
        <p class="item">
          8.2. Пользователь обязан ознакомиться с Политикой ПД перед началом
          использования Программы и Сайта.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import urlMixin from "@/views/Documents/urlMixin";
export default {
  name: "LicenseAgreement",
  metaInfo: {
    title: "ЭО Порт транзит | Лицензионное соглашение",
  },
  mixins: [urlMixin],
};
</script>

<style lang="scss" scoped>
@import "./styles";
</style>
