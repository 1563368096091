import { Request, getParamsUrl } from "@/core";
import { terminals } from "@/constants/urls";

export default {
  // список терминалов
  getTerminals(data) {
    const paramsFormatted = getParamsUrl(data);
    return Request.get(`${terminals.getTerminals}${paramsFormatted}`);
  },
  // инструкция по бронированию на терминале
  getTerminalInstruction(data) {
    const paramsFormatted = getParamsUrl(data);
    return Request.get(`${terminals.getTerminalInstruction}${paramsFormatted}`);
  },
  // настройки терминала (поля для получения/подтверждения таймслота)
  getTerminalSettings(data) {
    const paramsFormatted = getParamsUrl(data);
    return Request.get(`${terminals.getTerminalSettings}${paramsFormatted}`);
  },
  // список культур терминала
  getTerminalCultures(data) {
    const paramsFormatted = getParamsUrl(data);
    return Request.get(`${terminals.getTerminalCultures}${paramsFormatted}`);
  },
  // список экспортеров терминала
  getTerminalExporters(data) {
    const paramsFormatted = getParamsUrl(data);
    return Request.get(`${terminals.getTerminalExporters}${paramsFormatted}`);
  },
  // список список поставщиков экспортера терминала
  getExporterSuppliers(data) {
    const paramsFormatted = getParamsUrl(data);
    return Request.get(`${terminals.getExporterSuppliers}${paramsFormatted}`);
  },
};
