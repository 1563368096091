<template>
  <section class="login">
    <div>
      <h1 class="login__title">Введите номер телефона</h1>

      <div class="font2-16-20 login__subtitle">
        Чтобы войти или зарегистрироваться
      </div>

      <PhoneInput
        title="Введите телефон"
        v-model="phone"
        v-mask="'+7 (###) ###-##-##'"
        @input="setPhone"
      />
    </div>

    <MainButton
      title="Продолжить"
      :disabled="!canLogin"
      styles="mobile"
      @button-clicked="checkTerms"
    />
  </section>
</template>

<script>
import loginMixin from "@/views/AuthViews/loginView/loginMixin";

export default {
  name: "MobileLoginView",
  mixins: [loginMixin],
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  height: 100vh;
  margin: 0 auto;
  padding: 74px 16px 10rem;

  &__title {
    font-family: $fontPrimary;
    font-size: 28px;
    line-height: 34px;
    font-weight: 500;
    color: $mainBlack;
    padding-right: 10px;
    margin-bottom: 12px;
  }

  &__subtitle {
    margin-bottom: 32px;
    font-weight: 500;
    color: $greyText;
  }
}
</style>
