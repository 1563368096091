<template>
  <component :is="currentView" />
</template>

<script>
import DesktopHomeView from "@/views/HomeView/DesktopHomeView";
import MobileHoneView from "@/views/HomeView/MobileHomeView";

export default {
  name: "HomeView",
  metaInfo: {
    title: "ЭО Порт транзит | Главная",
  },
  components: { DesktopHomeView, MobileHoneView },
  computed: {
    currentView() {
      return this.isMobile ? MobileHoneView : DesktopHomeView;
    },
  },
};
</script>

<style lang="scss" scoped></style>
