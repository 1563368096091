<template>
  <component
    :title="isRequestMode ? 'Перенос таймслота' : 'Подтверждение переноса'"
    :is="currentWrapper"
    @close="handleWrapperClose"
  >
    <div class="reschedule">
      <div
        v-if="isRequestMode && !isMobile"
        class="font1-24-30 reschedule__title"
      >
        Перенос таймслота
      </div>

      <KeepAlive>
        <RescheduleRequestForm
          v-if="isRequestMode"
          :timeslot="timeslot"
          @found="goToConfirm"
          @close="$emit('close')"
        />
      </KeepAlive>

      <RescheduleConfirmForm
        v-if="!isRequestMode"
        :timeslot="availableTimeslot"
        :old-id="timeslot.id"
        @confirmed="$emit('success')"
        @close="isRequestMode = true"
      />
    </div>
  </component>
</template>

<script>
import ModalWrapper from "@/components/UI/ModalWrapper";
import FullscreenMobileModal from "@/components/UI/modals/FullscreenMobileModal.vue";
import RescheduleRequestForm from "@/components/UI/modals/TimeslotRescheduleModal/RescheduleRequestForm";
import RescheduleConfirmForm from "@/components/UI/modals/TimeslotRescheduleModal/RescheduleConfirmForm";
import { mapGetters } from "vuex";
import { GET_FULL_TERMINAL_LIST } from "@/store/actions";
import { setTerminalTimezone, removeTerminalTimezone } from "@/core";

export default {
  name: "TimeslotRescheduleModal",
  components: {
    ModalWrapper,
    FullscreenMobileModal,
    RescheduleRequestForm,
    RescheduleConfirmForm,
  },
  props: {
    timeslot: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isRequestMode: true,
      availableTimeslot: null,
    };
  },
  computed: {
    ...mapGetters({
      terminalsList: GET_FULL_TERMINAL_LIST,
    }),
    currentWrapper() {
      return this.isMobile ? FullscreenMobileModal : ModalWrapper;
    },
  },
  mounted() {
    const currentTerminal = this.terminalsList.find(
      (item) => item.id === this.timeslot.terminal_id
    );
    setTerminalTimezone(currentTerminal.timezone_utc_offset);
  },
  beforeDestroy() {
    removeTerminalTimezone();
  },
  methods: {
    goToConfirm(availableTimeslot) {
      this.availableTimeslot = availableTimeslot;
      this.isRequestMode = false;
    },
    handleWrapperClose() {
      this.isRequestMode ? this.$emit("close") : (this.isRequestMode = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.reschedule {
  width: 440px;
  @media (max-width: 1279px) {
    width: 100%;
  }

  &__title {
    margin-bottom: 24px;
    color: $mainBlack;
    @media (max-width: 1279px) {
      text-align: center;
    }
  }
}
:deep .form__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  &-item.el-button.is-plain {
    width: 48%;
    font-size: 16px;

    &.long {
      @media (max-width: 1279px) {
        width: 100%;
      }
    }
  }
}
</style>
