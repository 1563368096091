<template>
  <section class="fullscreen">
    <div class="fullscreen__header">
      <img
        src="@/assets/images/fat-close.svg"
        alt="close"
        @click="$emit('close')"
      />
      <div class="font1-20-24 fullscreen__header-title">{{ title }}</div>
    </div>

    <slot />

    <Component
      v-if="rightButton"
      :is="currentButtonComponent"
      :title="rightButton"
      :class="['fullscreen__button', { disabled: disabled }]"
      @button-clicked="handleSubmit"
    />
  </section>
</template>

<script>
// универсальный компонент мобильной модалки на весь экран
// по структуре почти идентичен компоненту TwoButtonsModal,
// описание пропсов/событий можно посмотреть там.
import AlarmButton from "@/components/UI/controls/buttons/AlarmButton.vue";
import MainButton from "@/components/UI/controls/buttons/MainButton.vue";

export default {
  name: "FullscreenMobileModal",
  components: { AlarmButton, MainButton },
  props: {
    title: {
      type: String,
      default: "",
    },
    rightButton: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentButtonComponent() {
      return this.success ? "MainButton" : "AlarmButton";
    },
  },
  methods: {
    handleSubmit() {
      if (!this.disabled) {
        this.$emit("accept");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 80px 16px 16px;
  background: #fff;
  overflow: auto;
  z-index: 7;

  &__header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 16px 16px 0 0;
    background: #fff;
    z-index: 15;

    &-title {
      font-weight: 500;
      color: $mainBlack;
    }
  }

  &__button {
    margin-top: 24px;
    width: 100%;

    &.disabled {
      opacity: 0.5;
    }
  }
}
</style>
